import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Image } from 'tfc-components';

import newProduct from 'assets/images/new_product.png';
import productPrize from 'assets/images/prizes.png';
import starLight1 from 'assets/images/star_light.svg';
import starLight2 from 'assets/images/star_light_2.svg';
import ButtonCustom from 'components/atoms/ButtonCustom';
import MainLayout from 'components/templates/MainLayout';
import useUTMParams from 'hooks/useUTMParams';
import { useAppSelector } from 'store/hooks';

const Home: React.FC = () => {
  const navigate = useNavigate();
  const utmParams = useUTMParams();
  const eventEndedTime = useAppSelector((state) => state.system.system?.others?.eventEndedTime);

  return (
    <MainLayout bottomNav>
      <div className="p-home">
        <div className="p-home_newProduct">
          <Image
            imgSrc={newProduct}
            ratio={375 / 317}
            alt="new product"
            extendClasses="o-introduceNabati_image"
          />
          <div className="p-home_newProduct_star p-home_newProduct_star-1">
            <Image imgSrc={starLight1} alt="star light" ratio={1 / 1} />
          </div>
          <div className="p-home_newProduct_star p-home_newProduct_star-2">
            <Image imgSrc={starLight2} alt="star light" ratio={1 / 1} />
          </div>
          <div className="p-home_newProduct_star p-home_newProduct_star-3">
            <Image imgSrc={starLight1} alt="star light" ratio={1 / 1} />
          </div>
          <div className="p-home_newProduct_star p-home_newProduct_star-4">
            <Image imgSrc={starLight1} alt="star light" ratio={1 / 1} />
          </div>
          <div className="p-home_newProduct_star p-home_newProduct_star-5">
            <Image imgSrc={starLight2} alt="star light" ratio={1 / 1} />
          </div>
        </div>
        <div className="p-home_prize">
          <Image imgSrc={productPrize} alt="Nabati" ratio={311 / 283} />
        </div>
        <div className="p-home_controls">
          <ButtonCustom
            effects={['infinity-gradient']}
            onClick={() => navigate(eventEndedTime ? 'ket-thuc-dot' : `/thong-tin-khach-hang?${utmParams.toString()}`)}
          >
            THAM GIA NGAY
          </ButtonCustom>
          <ButtonCustom onClick={() => window.open(`${window.location.origin}/program-rules.pdf`, '_blank')}>THỂ LỆ CHƯƠNG TRÌNH</ButtonCustom>
        </div>
      </div>
    </MainLayout>
  );
};

export default Home;
