/* eslint-disable */
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useRef, useState } from "react";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import Select from "react-select/dist/declarations/src/Select";
import { Checkbox, Typography } from "tfc-components";

import useAddressOptions from "./useAddressOptions";

import ButtonCustom from "components/atoms/ButtonCustom";
import Error from "components/atoms/Error";
import InputCustom from "components/atoms/InputCustom";
import LoadingFullscreen from "components/atoms/LoadingFullscreen";
import FormField from "components/molecules/FormField";
import Pulldown from "components/molecules/Pulldown";
import CustomModal from "components/organisms/Modal";
import PrizeModal from "components/organisms/PrizeModal";
import MainLayout from "components/templates/MainLayout";
import useUTMParams from "hooks/useUTMParams";
import { useQuery } from "react-query";
import returnValidateErrorMessages from "services/common/errors";
import {
  postCustomerService,
  postZaloOACustomerService,
} from "services/customer";
import { CustomerParamsTypes } from "services/customer/types";
import { getInfoZaloOAService } from "services/zaloOA";
import { Telco } from "utils/contants";
import useSchemas from "utils/schemas";
import { formatDateDDhhmmss } from "utils/functions";
import { useNavigate } from "react-router-dom";

const fieldToText = (field: string) => {
  switch (field) {
    case "phone":
      return "Số điện thoại";
    case "fullName":
      return "Họ và tên";
    case "code":
      return "Mã dự thưởng";
    case "provinceCode":
      return "Tỉnh/Thành";
    case "districtCode":
      return "Quận/Huyện";
    case "storeId":
      return "Nơi mua hàng";
    default:
      return "";
  }
};

export interface CustomerInfoFormTypes {
  fullName: string;
  phone: string;
  homeNetwork: OptionType;
  code: string;
  provinceCode: OptionType;
  districtCode: OptionType;
  placeOfPurchase: OptionType;
  agree: boolean;
}

const CustomerInfo: React.FC = () => {
  /* Hooks */
  const navigate = useNavigate();
  const { customerInfo } = useSchemas();
  // const [searchParams, setSearchParams] = useSearchParams({
  //   fullName: '',
  //   phone: '',
  //   code: '',
  //   provinceValue: '',
  //   provinceLabel: '',
  //   districtValue: '',
  //   districtLabel: '',
  //   storeValue: '',
  //   storeLabel: '',
  //   homeNetwork: '',
  //   agree: 'false',
  // });
  // const navigation = useNavigate();
  // get utm params
  const utmParams = useUTMParams();
  const utmSource = utmParams.get("utm_source");
  const tokenFromZaloOA = utmParams.get("token");

  const method = useForm<CustomerInfoFormTypes>({
    mode: "onChange",
    resolver: yupResolver(customerInfo),
    defaultValues: {
      fullName: "",
      phone: "",
      code: "",
      agree: false,
    },
  });

  const provinceWatch = useWatch({
    name: "provinceCode",
    control: method.control,
  });
  const districtWatch = useWatch({
    name: "districtCode",
    control: method.control,
  });

  const {
    provinces,
    districts,
    stores,
    isLoadingProvinces,
    isLoadingDistricts,
    isLoadingStores,
  } = useAddressOptions(provinceWatch?.value, districtWatch?.value);

  /* States */
  // const [popupPolicy, setPopupPolicy] = useState<boolean>(false);
  const [isPopupRegister, setIsPopupRegister] = useState<{
    type?: "success" | "failed";
    isOpen: boolean;
  }>({
    type: undefined,
    isOpen: false,
  });

  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  /* Refs */
  const provinceRef = useRef<Select>(null);
  const districtRef = useRef<Select>(null);
  const placeOfPurchaseRef = useRef<Select>(null);

  const { isLoading: isLoadingInfoFromZaloOA } = useQuery(
    ["getInfoCustomerFromOA", tokenFromZaloOA],
    () => {
      if (tokenFromZaloOA) {
        return getInfoZaloOAService(tokenFromZaloOA);
      }
      return null;
    },
    {
      onSuccess(data) {
        if (data) {
          if (data.name) method.setValue("fullName", data.name);

          // const phoneTest = '84335309208';
          // const telTest = undefined;
          if (data.phone)
            method.setValue("phone", data.phone.replace(/^84/, "0"));

          if (data.telco) {
            const tel = Telco.find((telco) => telco.value === data.telco);
            if (tel) {
              method.setValue("homeNetwork", tel);
            }
          } else {
            if (data.phone) {
              const phone = data.phone.replace(/^84/, "0");
              const prefix = phone.slice(0, 3);
              const tel = Telco.find((telco) => telco.prefix?.includes(prefix));
              if (tel) {
                method.setValue("homeNetwork", tel);
              }
            }
          }

          if (data.province) {
            method.setValue("provinceCode", {
              label: data.province.name,
              value: data.province.code,
            });
          }

          if (data.district) {
            method.setValue("districtCode", {
              label: data.district.name,
              value: data.district.code,
            });
          }
        }
      },
      enabled: !!tokenFromZaloOA,
    }
  );

  const resetForm = () => {
    // setSearchParams({});
    method.reset({});
    method.clearErrors();
    provinceRef.current?.clearValue();
    districtRef.current?.clearValue();
    placeOfPurchaseRef.current?.clearValue();
  };

  const onHandleSubmit = async (data: CustomerInfoFormTypes) => {
    try {
      setLoading(true);
      const customerData: CustomerParamsTypes = {
        fullName: data.fullName,
        phone: data.phone,
        telco: data.homeNetwork.value,
        code: data.code.toUpperCase(),
        provinceCode: data.provinceCode.value,
        districtCode: data.districtCode.value,
        storeId: data.placeOfPurchase.value,
        ...(tokenFromZaloOA && {
          token: tokenFromZaloOA,
        }),
      };
      if (utmSource && utmSource === "zalo") {
        const data = await postZaloOACustomerService({
          ...customerData,
          token: tokenFromZaloOA || "",
        });
        setIsPopupRegister({
          type: !!data.prize ? "success" : "failed",
          isOpen: true,
        });
      } else {
        const data = await postCustomerService(customerData);
        setIsPopupRegister({
          type: !!data.prize ? "success" : "failed",
          isOpen: true,
        });
      }
      resetForm();
    } catch (error: any) {
      if (error.length > 0) {
        error.forEach((err: ValidateError) => {
          if (err.code === "notAvailableTimeConfig") {
            setError("Chương trình khuyến mãi<br />hiện chưa bắt đầu");
          } else if (err.code === "eventHasEnded") {
            navigate("/ket-thuc-dot");
          } else {
            method.setError(err.field, {
              message: returnValidateErrorMessages(err, fieldToText(err.field)),
            });
          }
        });
      } else {
        if (error.response.status === 500) {
          setError("Hệ thống mạng hiện không ổn định.<br />Vui lòng thử lại.");
        } else {
          setError(
            `Đã có lỗi xảy ra.<br />Vui lòng thực hiện lại.<br />${
              error.response.status
            }-${formatDateDDhhmmss(error.response.headers.date)}`
          );
        }
      }
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   const search = Object.fromEntries(searchParams);

  //   if (search.fullName) method.setValue('fullName', search.fullName);
  //   if (search.phone) {
  //     method.setValue('phone', search.phone);
  //     const prefix = search.phone.slice(0, 3);
  //     const tel = Telco.find((telco) => telco.prefix?.includes(prefix));
  //     if (tel) {
  //       method.setValue('homeNetwork', tel);
  //       method.clearErrors('homeNetwork');
  //     } else {
  //       method.setValue('homeNetwork', { label: 'Chọn nhà mạng', value: '' });
  //       method.setError('homeNetwork', { message: 'Vui lòng cung cấp thông tin' });
  //     }
  //   }
  //   if (search.code) method.setValue('code', search.code);
  //   if (search.agree) method.setValue('agree', search.agree === 'true');
  //   if (search.provinceValue) {
  //     method.setValue('provinceCode', { label: search.provinceLabel, value: search.provinceValue });
  //   }
  //   if (search.districtValue) {
  //     method.setValue('districtCode', { label: search.districtLabel, value: search.districtValue });
  //   }
  //   if (search.storeValue) {
  //     method.setValue('placeOfPurchase', { label: search.storeLabel, value: Number(search.storeValue) });
  //   }
  // }, [searchParams]);

  return (
    <MainLayout bottomNav isBackButton>
      {isLoadingInfoFromZaloOA && <LoadingFullscreen />}
      <div className="p-customerInfo">
        <Typography.Heading
          type="h2"
          extendClasses="p-customerInfo_heading"
          textStyle="center"
          fontweight="400"
        >
          Thông Tin Khách Hàng
        </Typography.Heading>
        <div className="p-customerInfo_form">
          <FormProvider {...method}>
            <form onSubmit={method.handleSubmit(onHandleSubmit)}>
              <FormField label="Họ và tên" required className="u-mb-12">
                <Controller
                  name="fullName"
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <InputCustom
                        {...field}
                        type="text"
                        placeholder="Nhập họ và tên"
                        error={!!error?.message}
                      />
                      {error && <Error errText={error.message} />}
                    </>
                  )}
                />
              </FormField>
              <div className="p-customerInfo_row">
                <FormField label="Số điện thoại" required className="u-mb-12">
                  <Controller
                    name="phone"
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <InputCustom
                          {...field}
                          type="number"
                          placeholder="Nhập số điện thoại"
                          error={!!error?.message}
                          onChange={(e) => {
                            const value = e.target.value;
                            field.onChange(value);

                            if (value.length >= 3) {
                              const prefix = value.slice(0, 3);
                              const tel = Telco.find((telco) =>
                                telco.prefix?.includes(prefix)
                              );
                              if (tel) {
                                method.setValue("homeNetwork", tel);
                                method.clearErrors("homeNetwork");
                              } else {
                                method.setValue("homeNetwork", {
                                  label: "Chọn nhà mạng",
                                  value: "",
                                });
                                method.setError("homeNetwork", {
                                  message: "Vui lòng cung cấp thông tin",
                                });
                              }
                            }
                          }}
                        />
                        {error && <Error errText={error.message} />}
                      </>
                    )}
                  />
                </FormField>
                <FormField label="Nhà mạng" required className="u-mb-12">
                  <Controller
                    name="homeNetwork"
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <Pulldown
                          {...field}
                          name="homeNetwork"
                          placeholder="Chọn nhà mạng"
                          handleChange={field.onChange}
                          options={Telco}
                          error={!!error?.message}
                        />
                        {error && <Error errText={error.message} />}
                      </>
                    )}
                  />
                </FormField>
              </div>
              <FormField label="Mã dự thưởng" required className="u-mb-12">
                <Controller
                  name="code"
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <InputCustom
                        {...field}
                        type="text"
                        placeholder="Nhập Mã dự thưởng"
                        error={!!error?.message}
                      />
                      {error && <Error errText={error.message} />}
                    </>
                  )}
                />
              </FormField>
              <FormField label="Tỉnh/thành" required className="u-mb-12">
                <Controller
                  name="provinceCode"
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <Pulldown
                        {...field}
                        ref={provinceRef}
                        name="provinceCode"
                        placeholder="Chọn tỉnh/thành"
                        handleChange={(option) => {
                          if (districtWatch) {
                            districtRef.current?.clearValue();
                          }
                          field.onChange(option);
                        }}
                        options={provinces}
                        loading={isLoadingProvinces}
                        error={!!error?.message}
                      />
                      {error && <Error errText={error.message} />}
                    </>
                  )}
                />
              </FormField>
              <FormField label="Quận/huyện" required className="u-mb-12">
                <Controller
                  name="districtCode"
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <Pulldown
                        {...field}
                        name="districtCode"
                        ref={districtRef}
                        placeholder="Chọn quận/huyện"
                        handleChange={(option) => {
                          field.onChange(option);
                        }}
                        options={districts}
                        loading={isLoadingDistricts}
                        error={!!error?.message}
                      />
                      {error && <Error errText={error.message} />}
                    </>
                  )}
                />
              </FormField>
              <FormField label="Nơi mua hàng" required className="u-mb-12">
                <Controller
                  name="placeOfPurchase"
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <Pulldown
                        {...field}
                        ref={placeOfPurchaseRef}
                        placeholder="Chọn nơi mua hàng"
                        name="placeOfPurchase"
                        handleChange={field.onChange}
                        options={stores}
                        loading={isLoadingStores}
                        error={!!error?.message}
                      />
                      {error && <Error errText={error.message} />}
                    </>
                  )}
                />
              </FormField>
              <div className="u-mt-16">
                <Controller
                  name="agree"
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <Checkbox
                        {...field}
                        value={field.value}
                        checked={field.value}
                        id="agree"
                        size={20}
                        color="#9840AF"
                        activeColor="#9840AF"
                        extendClasses="p-customerInfo_checkbox"
                      >
                        <p className="p-customerInfo_checkbox-text">
                          Tôi đồng ý với{" "}
                          <span
                            className="p-customerInfo_checkbox-link"
                            onClick={(e) => {
                              e.preventDefault();
                              // set params data to search
                              // setSearchParams({
                              //   fullName: method.getValues('fullName'),
                              //   phone: method.getValues('phone'),
                              //   code: method.getValues('code'),
                              //   provinceValue: method.getValues('provinceCode')?.value || '',
                              //   provinceLabel: method.getValues('provinceCode')?.label || '',
                              //   districtValue: method.getValues('districtCode')?.value || '',
                              //   districtLabel: method.getValues('districtCode')?.label || '',
                              //   storeValue: method.getValues('placeOfPurchase')?.value || '',
                              //   storeLabel: method.getValues('placeOfPurchase')?.label || '',
                              //   homeNetwork: method.getValues('homeNetwork')?.value || '',
                              //   agree: method.getValues('agree')?.toString(),
                              // });
                              // navigation('/privacy');
                              window.open(
                                `${window.location.origin}/program-rules.pdf`,
                                "_blank"
                              );
                            }}
                          >
                            Điều kiện & Điều khoản tham gia.
                          </span>
                        </p>
                      </Checkbox>
                      {error && (
                        <Error className="u-mt-4" errText={error.message} />
                      )}
                    </>
                  )}
                />
              </div>
              <div className="u-mt-16 p-customerInfo_button">
                <ButtonCustom
                  type="submit"
                  loading={loading}
                  disabled={loading}
                >
                  Tiếp tục
                </ButtonCustom>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
      {/* <CustomModal
        isOpen={popupPolicy}
        type="privacy"
        handleClose={() => setPopupPolicy(false)}
      >
        <Typography.Heading type="h3" extendClasses="p-customerInfo_popup_heading" textStyle="center">
          Điều khoản & điều kiện tham gia
        </Typography.Heading>
        <div className="p-customerInfo_popup_content" dangerouslySetInnerHTML={{ __html: contentPolicy }} />
      </CustomModal> */}
      <CustomModal isOpen={!!error} handleClose={() => setError("")}>
        <Typography.Heading
          type="h3"
          extendClasses="p-customerInfo_popup_heading"
          textStyle="center"
        >
          <span dangerouslySetInnerHTML={{ __html: error }} />
        </Typography.Heading>
      </CustomModal>
      <PrizeModal
        isOpen={isPopupRegister.isOpen}
        variant={isPopupRegister.type === "success" ? "winPrize" : "failPrize"}
        handleClose={() =>
          setIsPopupRegister({
            ...isPopupRegister,
            isOpen: false,
          })
        }
        header={
          isPopupRegister.type === "success"
            ? "Chúc mừng quý khách hàng!"
            : "Chúc may mắn lần sau!"
        }
        prizeAmount="10000"
        notifyText={
          isPopupRegister.type === "success"
            ? "Phần thưởng sẽ tự động nạp vào số điện thoại đã đăng ký của quý khách hàng trong vòng 20 phút!"
            : "Cảm ơn quý khách đã tham gia chương trình khuyến mãi của Nabati VN!"
        }
        description="*Lưu ý: Mã dự thưởng của bạn vẫn có cơ hội tham gia chương trình quay số trúng thưởng tháng và giải Đặc biệt của Nabati. Theo dõi Fanpage Nabati Việt Nam hoặc truy cập website nabativn.com để biết thêm thông tin chi tiết bạn nhé."
      />
    </MainLayout>
  );
};

export default CustomerInfo;
